import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IconsModule } from 'src/app/core/modules/icons/icons.module';
import { GlobalService } from 'src/app/core/services/global.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-menu',
  templateUrl: `./templates/user-menu.${environment.template_id}.component.html`,
  styleUrls: [`./styles/user-menu.${environment.template_id}.component.scss`],
  standalone: true,
  imports: [
    CommonModule,
    IconsModule,
    RouterModule
  ]
})
export class UserMenuComponent implements OnInit {

  hover = false;

  constructor(
    public globalService: GlobalService,
    private _userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  openAccount(){
    this.router.navigate(['/perfil']);
  }

  loginInit(){
    this.globalService.requireSignin();
  }

  logout(){
    this._userService.logout().subscribe(()=>{
      this.globalService.logout();
    })
  }

}
