<div class="menu">
    <button class="btn btn--clear btn--mobile-menu" (click)="openMenu()">
      <app-icon
        icon="menu"
        class="icon">
      </app-icon>
    </button>
</div>

<ng-container #sidebarTemplate>
</ng-container>