import { AfterViewInit, Component, ComponentRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from 'src/app/core/modules/icons/icons.module';

@Component({
  selector: 'app-button-menu',
  standalone: true,
  imports: [
    CommonModule,
    IconsModule
  ],
  templateUrl: './button-menu.component.html',
  styleUrls: ['./button-menu.component.scss']
})
export class ButtonMenuComponent implements AfterViewInit{

  @Input() menu;
  @ViewChild('sidebarTemplate', {read: ViewContainerRef}) container: ViewContainerRef;
  contentRef: ComponentRef<any>;

  ngAfterViewInit(): void {
    try {
      this.instanceContent();
    } catch (error) {
      console.error(error);
    }
  }

  openMenu(){
    this.contentRef.setInput('menuOpen', true);
  }

  async instanceContent(){

    const ContentComponent = await import("../sidebar/sidebar.component").then(m=>m.SidebarComponent);

    this.contentRef = (this.container.createComponent(ContentComponent) as ComponentRef<any>);

    this.contentRef.setInput('menuOpen', false);
    this.contentRef.setInput('menu', this.menu);
    
    //Ref all events
    this.contentRef.instance?.$onClose?.subscribe(($event: boolean)=>{
      //this.closeCart();
    });

  }

}
