import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

// services
import { GlobalService } from './global.service'


@Injectable({
  providedIn: 'root'
})

export class GiftService {

  url: string;

  constructor(
    private _http: HttpClient,
    private _globalService: GlobalService
  ) { 
    this.url = `${ this._globalService.apiUrl }gift`
  }

  getGift() {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }`, options)
  }


  getGiftByParam(key: string, value: string, queryParams?) {

    let options = this._globalService.headersBuilder(true)

    if(queryParams){
      
      let params = new HttpParams();
      
      for(let key in queryParams){
        if (
          queryParams.hasOwnProperty(key) &&
          queryParams[key] != ''
        ) {
          params = params.set(key, queryParams[key]);
        }
      }

      options.params = params;

    }

    return this._http.get(`${ this.url }/${ key }/${ value }`, options);
    
  }

  getGiftSlimByParam(key, value) {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/slim/${ key }/${ value }`, options)
  }


  getGiftCategories(giftId) {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/id/${ giftId }/categories`, options)
  }


  getGiftImages(giftId) {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/id/${ giftId }/images`, options)
  }

  getFavorites(id?) {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/favorites${id?'/'+id:''}`, options)
  }

  getFavoritesByHash(hash) {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/favorites/hash/${hash}`, options)
  }

  setFavorite(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)
    return this._http.put(`${ this.url }/favorite`, form, options)
  }

  unsetFavorite(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)
    return this._http.post(`${ this.url }/favorite/unset`, form, options)
  }

  createList(form){
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)
    return this._http.post(`${ this.url }/favorites`, form, options)
  }

  deleteList(id){
    let options = this._globalService.headersBuilder(true)
    return this._http.delete(`${ this.url }/favorites/${id}`, options)
  }

  getGiftList(form){
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)
    return this._http.post(`${ this.url }/favorite/unset`, form, options)
  }

  getGiftcard(qr, email) {
    let options = this._globalService.headersBuilder()
    return this._http.get(`${ this.url }/giftcard/qr/${ qr }/email/${ email }`, options)
  }

  getInstallmentsInfoByGiftId(id) {
    let options = this._globalService.headersBuilder();
    return this._http.get(`${this.url}/id/${id}/installments`, options);
  }

}
