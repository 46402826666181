import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

// services
import { GlobalService } from './global.service'


@Injectable({
  providedIn: 'root'
})

export class PaymentService {

  url: string;

  constructor(
    private _http: HttpClient,
    private _globalService: GlobalService
  ) { 
    this.url = `${ this._globalService.apiUrl }transaction`;
  }


  setTransaction(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)

    return this._http.post(`${ this.url }`, form, options)
  }

  setPotentialPurchase(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)
    return this._http.post(`${ this.url }/potencial/payment`, form, options)
  }

  getTransaction(hash) {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/hash/${ hash }`, options)
  }

  getTransactionMercadopago(externalRef) {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/reference/${ externalRef }`, options)
  }

  setPayment(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)

    return this._http.post(`${ this.url }/payment`, form, options)
  }

  getPayments() {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/profile`, options)
  }

  getCart(id) {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/cart/${id}`, options)
  }

  getMethodPayments(){
    let options = this._globalService.headersBuilder(true);
    return this._http.get(`${this.url}/payment/method`, options);
  }

  getMethodPaymentsInstallments(){
    let options = this._globalService.headersBuilder(true);
    return this._http.get(`${this.url}/payment/method/installments`, options);
  }

}
