import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

// services
import { GlobalService } from './global.service'


@Injectable({
  providedIn: 'root'
})

export class DynamicService {

  url: string;

  constructor(
    private _http: HttpClient,
    private _globalService: GlobalService
  ) { 
    this.url = `${ this._globalService.apiUrl }dynamic`
  }

  getSections() {
    let options = this._globalService.headersBuilder(false)
    return this._http.get(`${ this.url }/website/sections`, options)
  }

}