import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive(
  { 
    selector: 'img',
    standalone: true
  }
)
export class LazyImgDirective implements AfterViewInit{

  constructor(
    private el: ElementRef,
    private _renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    this._renderer.setAttribute(this.el.nativeElement, 'loading', 'lazy');
    this._renderer.setAttribute(this.el.nativeElement, 'fetchpriority', 'low');
  }

}
