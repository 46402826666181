<div
    class="logo"
    (click)="gs.navigate(['/'])"
    [style.max-height.px]="maxHeight"
>
  <ng-container *ngIf="gs.website.logo; else onlyTitleTemplate">
    <img
      class="logo_image"
      [src]="gs.website.logo"
      [style.max-width.px]="gs.header.logo_size"
      [style.width.px]="gs.header.logo_size"
      [style.object-position]="position"
      [width]="gs.header.logo_size"
      [alt]="'Logo '+gs.website.title"
      size="sm"
      [disabledLQIP]="true"
      lqip
    />
  </ng-container>
  <ng-template #onlyTitleTemplate>
    <h1 class="logo_title">
      {{ gs.website.title }}
    </h1>
  </ng-template>
</div>
