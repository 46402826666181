import { Directive, ElementRef } from '@angular/core';
import { GlobalService } from '../services/global.service';

@Directive({
  selector: 'img',
  standalone: true
})
export class CDNImgDirective {

  constructor(
    private el: ElementRef,
    private _globalService: GlobalService
  ) { }

  ngAfterViewInit(){

    if(this.el.nativeElement.src){
      //Extraer el path de un src
      if(this._globalService.isBrowser){
        try{
          //Navegador
          let src = this.el.nativeElement.src;
          let url = new URL(src);
          let path = url.pathname;
          let hostname = url.hostname;
          if(hostname == window.location.hostname){
            let isAssets = path.split('assets')[0] == '/';
            if(isAssets){
              this.el.nativeElement.src = `${this._globalService.templateUrl}${path}`;
            }
          }
        }catch(err){
          console.log("Error en el src:", this.el.nativeElement.src);
        }
      }else{
        //Sevidor
        let src = this.el.nativeElement.src;
        let isAssets = src.split('assets')[0] == '/';
        if(isAssets){
          this.el.nativeElement.src = `${this._globalService.templateUrl}${src}`;
        }

      }
    }


  }

}
