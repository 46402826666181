<div class="shopping">

  <!-- BUTTON -->
  <div class="shopping__text" (click)="clickCart()">
    <div class="img">

      <app-icon class="icon" icon="shopping_cart">
      </app-icon>

      <div class="badge animated" *ngIf="cartService.quantityTotal">
        {{  cartService.quantityTotal || 0}}
      </div>

    </div>
  </div>

  <!-- CONTENT -->
  <aside class="shopping__content" [style.right]="openMenu ? '0': '-100vw'">

    <!-- HEADER -->
    <div class="shopping__content__header">
      <span><b>Mi carrito</b></span>
      <app-icon
        icon="close"
        (click)="closeCart()">
      </app-icon>
    </div>

    <!-- CONTENT DYNAMIC -->
    <div class="shopping__content__content">
      <ng-container #contentTemplate>
      </ng-container>
    </div>

  </aside>

</div>

<div class="backdrop" *ngIf="openMenu" (click)="closeCart()">
</div>
