import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Item } from '../../interface/menu.interface';
import { TargetPipe } from 'src/app/core/pipes/target.pipe';
import { ElementComponent } from './components/element/element.component';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    TargetPipe,
    ElementComponent
  ],
  templateUrl: './navbar.component.html',
  styleUrls: [
    './navbar.component.scss',
    `./styles/navbar.component.${env.template_id}.scss`
  ],
})
export class NavbarComponent implements OnInit{

  // Design
  // @Input() elementsAlign: 'left' | 'right' | 'center' = 'center';
  // @Input() elementsSeparator: number = 20;
  @Input() limitSubElements: number = 0; // 0 es no tener limites

  // Info
  @Input() menu: Item[] = [];

  ngOnInit(): void {

    this.menu = this.menu.map((link)=>{
      
      link.url = this.calculateUrl(link);
      
      if(link.children?.length){
        link.children.map((link)=>{
          link.url = this.calculateUrl(link);
          if(link.children?.length){
            link.children.map((link)=>{
              link.url = this.calculateUrl(link);
            });
          }
        })
      }

      return link;

    });

  }

  calculateUrl(link: Item): string{
    switch(link.type_menu_item_id){
      case 1:
        return '/';
      case 2:
        return '/contacto';
      case 3:
        return link.category_url ? 'productos/'+link.category_url : (link.category_url ? link.category_url : 'productos')
      case 4:
        return null;
      case 5:
      case 6:
        return link.url
      case 7:
        return '/formulario-mayorista';
      default:
        return link.url;
    }
  }
  
}
