import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from 'src/app/core/modules/icons/icons.module';

@Component({
  selector: 'app-element',
  standalone: true,
  imports: [
    CommonModule,
    IconsModule
  ],
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss']
})
export class ElementComponent {

  @Input('text') text: string;
  @Input('url') url: string;
  @Input('target') target: string;
  @Input('showIconMore') showIconMore: boolean = false;

}
