export const sectionConfig = {
  1: {
    class: 'section',
    isBackgroundImage: false
  },
  2: {
    class: 'section',
    isBackgroundImage: false
  },
  3: {
    class: 'section',
    isBackgroundImage: false
  },
  5: {
    class: 'section section-complete',
    isBackgroundImage: false
  },
  6: {
    class: 'section section-complete',
    isBackgroundImage: false
  },
  8: {
    class: 'section',
    isBackgroundImage: false
  },
  9: {
    class: 'section section-bottom',
    isBackgroundImage: false
  },
  10: {
    class: 'section section-background',
    isBackgroundImage: true
  },
  11: {
    class: 'section section-complete',
    isBackgroundImage: false
  },
  13: {
    class: 'section',
    isBackgroundImage: false
  }
}
