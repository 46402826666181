import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser'
import { NgModule, LOCALE_ID } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
// FIXME import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io'

// base component
import { AppComponent } from './app.component'
// services
// config
// FIXME import { environment } from '../environments/environment'
// FIXME const socketConfig: SocketIoConfig = {
//         url: `${ environment.baseUrl }`,
//         options: {}
//       }
import es from '@angular/common/locales/es';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CookiesInterceptor } from './core/interceptor/cookies.interceptor';
import { AuthGuardService } from './core/services/auth-guard.service';
import { GlobalService } from './core/services/global.service';
import { GiftService } from './core/services/gift.service';
import { CategoryService } from './core/services/category.service';
import { UserService } from './core/services/user.service';
import { PaymentService } from './core/services/payment.service';
import { WebsiteService } from './core/services/website.service';
import { ShipmentService } from './core/services/shipment.service';
import { DynamicService } from './core/services/dynamic.service';
import { CartService } from './core/services/cart.service';
import { AnalyticsService } from './core/services/analytics.service';
import { IconService } from './core/modules/icons/services/icon.service';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: `1`}),
    BrowserTransferStateModule,

    HttpClientModule,
    AppRoutingModule,

    HeaderComponent,
    FooterComponent,
    
  ],
  exports:[
  ],
  providers: [
    //Services
    AuthGuardService,
    GlobalService,
    GiftService,
    CategoryService,
    UserService,
    PaymentService,
    WebsiteService,
    ShipmentService,
    DynamicService,
    CartService,
    AnalyticsService,
    IconService,

    // Provides
    {provide: LOCALE_ID, useValue: 'es-AR'},
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: HTTP_INTERCEPTORS, useClass: CookiesInterceptor, multi: true},

  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { }
