import { GlobalService } from './../services/global.service';
import { isPlatformServer } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, Output, PLATFORM_ID, Renderer2 } from '@angular/core';
import { fromIntersectionObserver } from './from-intersection-observer';
import { Subscription } from 'rxjs';

@Directive(
  {
    selector: '[lqip]',
    standalone: true
  }
)
export class LQIPDirective implements AfterViewInit, OnDestroy{

  @Input() src: string;
  @Input() size: 'sm' | 'md' | 'lg';
  @Input() disabledLQIP: boolean = false;
  @Input() lazyByViewport: boolean = true;
  @Output('imageLoaded') imageLoaded$: EventEmitter<boolean> = new EventEmitter<boolean>();

  observeViewport$: Subscription;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private gs: GlobalService,
    @Inject(PLATFORM_ID) private platformId,
  ) { }

  ngAfterViewInit() {

    if(!this.src || this.src.includes('/assets/images/empty-image') || /\bbase64\b/.test(this.src)){
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.src || this.gs.emptyImage);
      return;
    }

    // Generate the LQIP image path
    let lqipSrc = this.src + "?class=lqip";
    let dataSrc = this.size ? this.src + `?class=${this.size}` : this.src;
    let isServer = isPlatformServer(this.platformId)

    this.renderer.setAttribute(this.el.nativeElement, 'src', dataSrc);

    if(!this.disabledLQIP){
      this.renderer.setStyle(this.el.nativeElement, 'background-repeat', 'no-repeat');
      this.renderer.setStyle(this.el.nativeElement, 'background-position', 'center');
      this.renderer.setStyle(this.el.nativeElement, 'background-size', 'cover');
      this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${lqipSrc})`);
    }
   
    //this.renderer.setAttribute(this.el.nativeElement, 'srcset', `${dataSrc} 2x`);


    if(isServer){
      // Set the LQIP image as the default
      //lqipSrc = lqipSrc.replace(/\.(png|jpg|jpeg)$/i, '.webp')
      //dataSrc = dataSrc.replace(/\.(png|jpg|jpeg)$/i, '.webp');
      // this.renderer.setAttribute(this.el.nativeElement, 'srcset', dataSrc);
      // this.renderer.setAttribute(this.el.nativeElement, 'src', lqipSrc);
      //this.renderer.setStyle(this.el.nativeElement, 'filter', 'blur(2px)');
      //this.renderer.setStyle(this.el.nativeElement, 'transition', '100ms');
    }else{
      // if(this.lazyByViewport){
      //   this.renderer.setAttribute(this.el.nativeElement, 'src', lqipSrc);
      //   this.observeViewport();
      // }else{
      //   this.renderer.setAttribute(this.el.nativeElement, 'src', dataSrc);
      //   this.imageLoaded$.emit(true);
      // }
    }

  }

  ngOnDestroy(): void {

    if(this.imageLoaded$)
      this.imageLoaded$.unsubscribe();

    if(this.observeViewport$)
      this.observeViewport$.unsubscribe();

  }

  observeViewport(){

    if(!this.observeViewport$){

      const element = this.el.nativeElement;
      const config = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      };

      this.observeViewport$ = fromIntersectionObserver(
        element,
        config,
        0
      ).subscribe((status) => {
        
        let dataSrc = this.size ? this.src + `?class=${this.size}` : this.src;

        switch (status) {
          case 'Visible':
            this.renderer.setAttribute(this.el.nativeElement, 'src', dataSrc);
            this.imageLoaded$.emit(true);
            break;
          case 'NotVisible':
            break;
        }

      });

    }

  }


  @HostListener('load', ['$event'])
  imageLoaded($event){
    this.renderer.setStyle(this.el.nativeElement, 'background-repeat', 'none');
    this.renderer.setStyle(this.el.nativeElement, 'background-position', 'none');
    this.renderer.setStyle(this.el.nativeElement, 'background-size', 'none');
    this.renderer.setStyle(this.el.nativeElement, 'background-image', 'none');
  }

  @HostListener('error')
  applyImageDefault(){
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.gs.emptyImage);
  }

}
