import { EventEmitter, Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  eventEmitted$: EventEmitter<AnalyticEvent> = new EventEmitter();
  eventsBuffer: AnalyticEvent[] = [];

  constructor(private _globalService: GlobalService) {}

  trackEvent(analyticEvent: AnalyticEvent) {
    if (this._globalService.isBrowser) {
      this.eventEmitted$.emit(analyticEvent);
      this.eventsBuffer.push(analyticEvent);
    }
  }
  
}

export interface AnalyticEvent {
  event:
    | 'add_to_cart'
    | 'page_view'
    | 'purchase'
    | 'begin_checkout'
    | 'sign_up'
    | 'view_item'
    | 'search'
    | 'remove_from_cart'
    | 'add_shipping_info'
    | 'add_payment_info'
    | 'newsletter_subscribe'
    | string;
  data: any;
}
