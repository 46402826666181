import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router'

// services
import { GlobalService } from './global.service'


@Injectable()

export class AuthGuardService implements CanActivate {

  constructor(
    private _globalService: GlobalService,
    private _router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {

    let token = this._globalService.getStorage('auth_token');

    if(token) {
      //this._globalService.isLogged = true
      return true
    }

    else {
      const nextUrl = state.url
      this._globalService.logout(nextUrl)
      return false
    }

  }

}
