<header
  class="header"
  id="section-header"
  [class.header_fixed]="globalService.header.is_fixed"
>

  <div
    class="header_container"
    [ngClass]="{
      header_transparent: !url && globalService.header.above_carrousel && globalService.header.transparent_carrousel,
      header_absolute: !url && globalService.header.above_carrousel,
      complete: completeColor || url
    }"
    [style.top.px]="
      globalService.header.visible_message ? 0 : 
      (-headerMessageElement?.nativeElement?.offsetHeight || 0)"
    #header
  >

    <app-message
      #messageElement
      [hidden]="!globalService.header.base_visible_message"
      [href]="globalService.header.message_link"
      [text]="globalService.header.message_text"
    >
    </app-message>

    <div class="content container">
      
      <app-logo class="logo"> </app-logo>

      <app-navbar [menu]="menu"> </app-navbar>

      <div class="actions">
        <div class="actions_element">
          <app-searchbar> </app-searchbar>
        </div>

        <div class="actions_element hidden-portrait">
          <app-user-menu> </app-user-menu>
        </div>

        <div class="actions_element">
          <app-shopping-cart #shoppingCart> </app-shopping-cart>
        </div>
      </div>

      <app-button-menu [menu]="menu">
      </app-button-menu>

    </div>

  </div>
  
</header>