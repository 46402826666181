import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GlobalService } from 'src/app/core/services/global.service';
import { environment } from 'src/environments/environment';
import { IconService } from '../../services/icon.service';

const templateUrl = `./icon.component.html`

@Component({
  selector: 'app-icon',
  templateUrl: templateUrl,
  styleUrls: [
    `./icon.component.scss`
  ],
})
export class IconComponent implements OnChanges{

  @Input('icon') icon: string; //Nombre del icono
  @Input('fill') fill: 0 | 1 = 0;
  @Input('opsz') opsz: number = 40;
  @Input('grad') grad: number = 0;
  @Input('wght') wght: number = environment.options.iconFontWeight;

  iconSvg: string;
  keyState: string;

  constructor(
    private _globalService: GlobalService,
    private _iconService: IconService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes.icon){
      this.keyState = `${this.icon}_${this.fill}_${this.wght}`;
      const keyState = this.keyState;
      const iconSvg = this._globalService.getState(keyState, null)

      if(!iconSvg){
        this.getIcon();
      }else{
        this.iconSvg = iconSvg;      
      }
    }

  }

  getIcon(){

    const keyState = this.keyState;
    
    // Si es el servidor uso el Origin de Digital Ocean
    // Si es browser uso el CDN si es prod, si no, uso el local
    const galleryUrl = this._globalService.isServer ? 
      `http://localhost:${4000 + (environment.name == '(PROD)' ? environment.template_id : 0)}/` : 
      environment.assetsUrl;

    const iconPath = `${galleryUrl}assets/icons/svg/${this.icon}_FILL${this.fill}_wght${this.wght}_GRAD0_opsz40.svg`; // Ruta del archivo SVG
    const iconSvg = this._globalService.getState(keyState, null);

    if(iconSvg){
      this.iconSvg = iconSvg;
    }else{
      this._iconService.getIcon(iconPath).subscribe((data)=>{
        this.iconSvg = data.replace('path', 'path fill="currentColor"');
        this._globalService.setState(keyState, this.iconSvg);
      })
    }
    
  }

}
