import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'

// services
import { GlobalService } from './global.service'


@Injectable({
  providedIn: 'root'
})

export class ShipmentService {

  url: string;

  constructor(
    private _http: HttpClient,
    private _globalService: GlobalService
  ) { 
    this.url = `${ this._globalService.apiUrl }shipment`
  }


  getShipmentTypes(paramsQuery) {
    let options = this._globalService.headersBuilder(true);
    options.params = new HttpParams();
    for(let key of Object.keys(paramsQuery)){
      options.params = options.params.append(key, paramsQuery[key])
    }
    return this._http.get(`${ this.url }/type/list`, options)
  }

}
