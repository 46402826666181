import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

// services
import { GlobalService } from './global.service'


@Injectable({
  providedIn: 'root'
})

export class CategoryService {

  url: string;

  constructor(
    private _http: HttpClient,
    private _globalService: GlobalService
  ) { 
    this.url = `${ this._globalService.apiUrl }category`
  }


  getCategory() {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }`, options)
  }


  getCategoriesTree(){
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${this.url}/tree`, options);
  }

  getCategoryByParam(key, value) {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/${ key }/${ value }`, options)
  }

  getFiltersByParam(key, value?) {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/${ key }${ value ? '/'+value: '' }`, options)
  }
}
