<a 
    class="element"
    [attr.href]="url" 
    [attr.target]="target"
    [class.contain-icon]="showIconMore"
>
    {{ text }}
    <app-icon 
        icon="keyboard_arrow_down"
        *ngIf="showIconMore"
    >
    </app-icon>
</a>