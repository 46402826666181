<form class="volar-form" [formGroup]="form" (ngSubmit)="search()">
  <div 
    class="searchbar desktop" 
    [ngClass]="{
      'active': active
    }"
  >
    <div class="searchbar__input">
      <input id="search" type="search" placeholder="¿Qué buscás?" formControlName="keywords" #searchbar>
      <button class="submit">
        <app-icon [icon]="'search'">
        </app-icon>
      </button>
    </div>
    <div class="searchbar__background">
    </div>
  </div>
  <div class="icon mobile" (click)="showSearchbar()">
    <app-icon
      [icon]="active ? 'close': 'search'"
    >
    </app-icon>
  </div>
</form>