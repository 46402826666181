import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-social-icons',
  templateUrl: './social-icons.component.html',
  styleUrls: ['./social-icons.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ]
})
export class SocialIconsComponent implements OnInit{

  @Input() tiktok: string;
  @Input() youtube: string;
  @Input() facebook: string;
  @Input() instagram: string;
  @Input() pinterest: string;
  @Input() twitter: string;
  @Input() whatsapp: string;

  size: 'xs' | 'md' | 'lg' = 'lg';
  @Input() forceSize: 'xs' | 'md' | 'lg';

  constructor(
    private _analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    
    let countSocial = 0;

    if (this.tiktok) {
      countSocial++;
    }
    
    if (this.youtube) {
      countSocial++;
    }
    
    if (this.facebook) {
      countSocial++;
    }
    
    if (this.instagram) {
      countSocial++;
    }
    
    if (this.pinterest) {
      countSocial++;
    }
    
    if (this.twitter) {
      countSocial++;
    }

    if(countSocial < 4) {
      this.size = 'md';
    }

    if(this.forceSize){
      this.size = this.forceSize;
    }

  }

  click(source: string){ 
    this._analyticsService.trackEvent({ event: `${source}_button`, data: {} });
  }

}
