import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { environment as env } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { LQIPDirective } from '../../directive/lqip.directive';

let template_id = env.template_id;
let maxHeight: number;
let position: 'center' | 'left' | 'right';

switch(template_id){
  case 49:
    maxHeight = 110;
    position = 'center';
    break;
  case 55:
  case 47:
    maxHeight = 80;
    position = 'left';
    break;
  case 2:
    position = 'left';
    break;
  default:
    position = 'center';
    maxHeight = 90;
    break;
}

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LQIPDirective
  ]
})
export class LogoComponent implements OnInit{

  maxHeight: number = maxHeight;
  position: 'center' | 'left' | 'right' = position;

  constructor(
    public gs: GlobalService
  ) { }

  ngOnInit(): void {
    let logo = this.gs.website.logo;
    if(logo){
      let filename = logo.split('/').pop();
      if(filename && filename == 'null'){
        this.gs.website.logo = null;
      }
    }
  }

}
