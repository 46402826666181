<div
  class="message"
>
  <a 
    class="message-text" 
    [href]="href" 
    target="_blank"
    [class.default]="!href"
  >
    {{ text }}
  </a>
</div>