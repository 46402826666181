<div class="social {{size}}">

  <a *ngIf="whatsapp" (click)="click('whatsapp')" [href]="whatsapp" target="_blank" class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <title>Logo Whatsapp</title>
      <path
        d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" 
        fill="currentColor"
      />
    </svg>
  </a>

  <a *ngIf="facebook" (click)="click('facebook')" [href]="facebook" target="_blank" class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <title>Logo Facebook</title>
      <path
        d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
        fill-rule="evenodd" fill="currentColor" />
    </svg>
  </a>
  <a *ngIf="instagram" (click)="click('instagram')" [href]="instagram" target="_blank" class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <title>Logo Instagram</title>
      <path
        d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"
        fill="currentColor" />
      <path
        d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z"
        fill="currentColor" />
    </svg>
  </a>
  
  <a *ngIf="twitter" (click)="click('x')" [href]="twitter" target="_blank" class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <title>Logo X</title>
      <path fill="currentColor"
        d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
    </svg>
  </a>
  <a *ngIf="tiktok" (click)="click('tiktok')" [href]="tiktok" target="_blank" class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        d="M 401.626 126.804 C 398.593 125.236 395.64 123.517 392.779 121.654 C 384.457 116.153 376.827 109.671 370.054 102.347 C 353.107 82.956 346.778 63.285 344.446 49.511 L 344.54 49.511 C 342.593 38.08 343.398 30.683 343.52 30.683 L 266.331 30.683 L 266.331 329.157 C 266.331 333.164 266.331 337.124 266.162 341.038 C 266.162 341.525 266.116 341.975 266.088 342.499 C 266.088 342.714 266.088 342.939 266.04 343.164 L 266.04 343.332 C 264.388 365.079 252.027 384.579 233.064 395.353 C 223.331 400.889 212.324 403.794 201.127 403.779 C 165.164 403.779 136.017 374.455 136.017 338.239 C 136.017 302.022 165.164 272.698 201.127 272.698 C 207.935 272.691 214.7 273.763 221.174 275.872 L 221.267 197.278 C 181.541 192.147 141.48 203.867 110.784 229.6 C 97.479 241.159 86.295 254.952 77.732 270.357 C 74.474 275.974 62.18 298.548 60.692 335.186 C 59.755 355.982 66 377.525 68.978 386.43 L 68.978 386.617 C 70.851 391.86 78.107 409.754 89.931 424.837 C 99.468 436.936 110.733 447.565 123.367 456.381 L 123.367 456.193 L 123.555 456.381 C 160.922 481.773 202.354 480.106 202.354 480.106 C 209.525 479.816 233.551 480.106 260.835 467.176 C 291.096 452.842 308.324 431.484 308.324 431.484 C 319.331 418.724 328.082 404.18 334.203 388.481 C 341.188 370.119 343.52 348.098 343.52 339.297 L 343.52 180.95 C 344.456 181.512 356.927 189.76 356.927 189.76 C 356.927 189.76 374.895 201.277 402.929 208.777 C 423.039 214.114 450.136 215.236 450.136 215.236 L 450.136 138.61 C 440.642 139.641 421.363 136.644 401.626 126.804 Z"
        fill="currentColor" />
    </svg>
  </a>
  <a *ngIf="youtube" (click)="click('youtube')" [href]="youtube" target="_blank" class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
      <title>Logo Youtube</title>
      <path
        d="M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9zM207 353.89v-196.5l145 98.2z"
        fill="currentColor" />
    </svg>
  </a>
  <a *ngIf="pinterest" (click)="click('pinterest')" [href]="pinterest" target="_blank" class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <title>Logo Pinterest</title>
      <path
        d="M256.05 32c-123.7 0-224 100.3-224 224 0 91.7 55.2 170.5 134.1 205.2-.6-15.6-.1-34.4 3.9-51.4 4.3-18.2 28.8-122.1 28.8-122.1s-7.2-14.3-7.2-35.4c0-33.2 19.2-58 43.2-58 20.4 0 30.2 15.3 30.2 33.6 0 20.5-13.1 51.1-19.8 79.5-5.6 23.8 11.9 43.1 35.4 43.1 42.4 0 71-54.5 71-119.1 0-49.1-33.1-85.8-93.2-85.8-67.9 0-110.3 50.7-110.3 107.3 0 19.5 5.8 33.3 14.8 43.9 4.1 4.9 4.7 6.9 3.2 12.5-1.1 4.1-3.5 14-4.6 18-1.5 5.7-6.1 7.7-11.2 5.6-31.3-12.8-45.9-47-45.9-85.6 0-63.6 53.7-139.9 160.1-139.9 85.5 0 141.8 61.9 141.8 128.3 0 87.9-48.9 153.5-120.9 153.5-24.2 0-46.9-13.1-54.7-27.9 0 0-13 51.6-15.8 61.6-4.7 17.3-14 34.5-22.5 48a225.13 225.13 0 0063.5 9.2c123.7 0 224-100.3 224-224S379.75 32 256.05 32z"
        fill="currentColor" />
    </svg>
  </a>
</div>
