import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

// services
import { GlobalService } from './global.service'


@Injectable({
  providedIn: 'root'
})

export class UserService {

  url: string;

  constructor(
    private _http: HttpClient,
    private _globalService: GlobalService
  ) {
    this.url = `${ this._globalService.apiUrl }user`
  }


  signup(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(false, form);

    return this._http.post(`${ this.url }/signup`, form, options)
  }

  signupWholesale(form){
    form.wholesale = true;
    form = JSON.stringify(form);
    let options = this._globalService.headersBuilder(false, form);
    return this._http.post(`${ this.url }/signup`, form, options)
  }

  signin(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(false, form)

    return this._http.post(`${ this.url }/signin`, form, options)
  }

  passwordAdd(form){
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)

    return this._http.post(`${ this.url }/password/add`, form, options)
  }

  passwordRecovery(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(false, form)

    return this._http.post(`${ this.url }/password/recovery`, form, options)
  }

  passwordUpdate(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(false, form)

    return this._http.post(`${ this.url }/password/update`, form, options)
  }

  getProfile() {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/profile`, options)
  }

  ping() {
    let options = this._globalService.headersBuilder(true);
    return this._http.post(`${ this.url }/ping`, null, options)
  }

  logout(){
    let options = this._globalService.headersBuilder(true)
    return this._http.post(`${ this.url }/logout`, null, options)
  }

  updateProfile(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)

    return this._http.put(`${ this.url }/profile`, form, options)
  }

  updateProfilePassword(form) {
    form = JSON.stringify(form)
    let options = this._globalService.headersBuilder(true, form)

    return this._http.put(`${ this.url }/profile/password`, form, options)
  }

  userActivation(hash) {
    let options = this._globalService.headersBuilder(true)
    return this._http.get(`${ this.url }/activation/${ hash }`, options)
  }

}
