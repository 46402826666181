import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CategoryService } from '../../../../services/category.service';
import { GlobalService } from '../../../../services/global.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { IconsModule } from 'src/app/core/modules/icons/icons.module';
import { AnalyticsService } from 'src/app/core/services/analytics.service';

@Component({
  selector: 'app-searchbar',
  templateUrl: `./templates/searchbar.${environment.template_id}.component.html`,
  styleUrls: [`./styles/searchbar.${environment.template_id}.component.scss`],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconsModule
  ]
})
export class SearchbarComponent implements OnInit {

  categories: any;
  form: UntypedFormGroup
  isFormLoaded: boolean
  locations: any
  isSearchbarActive: boolean
  searchSuggestions: any
  searchDelay: any

  active: boolean = false;
  
  @ViewChild('searchbar') searchbarElement: ElementRef;

  constructor(
    public globalService: GlobalService,
    private _analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = new UntypedFormGroup({
      'category': new UntypedFormControl(null),
      'keywords': new UntypedFormControl(null)
    })
    this.isFormLoaded = true
  }

  autocompleteSearchbar(keywords) {
    this.form.patchValue({
      keywords: keywords
    });
    this.search()
  }

  showSearchbar(){
    this.active = !this.active;
    if(this.active){
      setTimeout(()=>{
        this.searchbarElement.nativeElement.focus();
      }, 500);
    }
  }

  searchbarFocus(status) {
    setTimeout(() => {
      this.isSearchbarActive = status
    }, 500)
  }


  search() {
    
    let form = this.form.value,
        keywords: any = form.keywords

    if(keywords.trim()){
      
      this._analyticsService.trackEvent({
        'event': 'search',
        'data': { search_term: keywords.trim() }
      });

      this.globalService.navigate(['/productos/buscar'], { keywords: keywords.trim() });
      
    }

  }

}
