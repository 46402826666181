import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { CommonModule, Location } from '@angular/common';
import { environment as env } from 'src/environments/environment';
import { MessageComponent } from './components/message/message.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { LogoComponent } from '../logo/logo.component';
import { ButtonMenuComponent } from './components/button-menu/button-menu.component';
import { TargetPipe } from '../../pipes/target.pipe';

@Component({
  selector: 'app-header',
  templateUrl: `./templates/header.${env.template_id}.component.html`,
  styleUrls: [
    `./header.component.scss`,
    `./styles/header.${env.template_id}.component.scss`
  ],
  standalone: true,
  imports: [
    CommonModule,
    MessageComponent,
    NavbarComponent,
    ShoppingCartComponent,
    SearchbarComponent,
    UserMenuComponent,
    LogoComponent,
    ButtonMenuComponent,
    TargetPipe
  ]
})
export class HeaderComponent implements OnInit{

  @ViewChild('header')
  headerElement: ElementRef;

  @ViewChild('messageElement', { read: ElementRef })
  headerMessageElement: ElementRef;

  @ViewChild('shoppingCart')
  cartElement: ShoppingCartComponent;

  @ViewChild('shoppingCartMobile')
  cartElementMobile: ShoppingCartComponent;


  menu = [];

  //Variable del template
  completeColor: boolean = false;
  visibleFirstLine: boolean = true;
  url: string = '';

  constructor(
    public globalService: GlobalService,
    private location: Location,
  ) { }

  ngOnInit() {

    this.url = this.location.path().split("?")[0];
    this.menu = this.globalService.menu;

    this.globalService.openCart.subscribe(
      (openCart?)=>{

        if(this.globalService.isMobile()){
          
          if(this.cartElementMobile){
            if(openCart)
              this.cartElementMobile.clickCart(3000);
            else
              this.cartElementMobile.updateView();
          }else{
            if(openCart){
              this.cartElement.clickCart(3000);
            }else{
              this.cartElement.updateView();
            }  
          }

        }else{

          if(openCart){
            this.cartElement.clickCart(3000);
          }else{
            this.cartElement.updateView();
          }

        }

      }
    )

  }


  @HostListener("window:scroll", ['$event'])
  modifyColorHeader($event){

    if(this.globalService.isBrowser){
      
      if(this.globalService.header.is_fixed){ //Tema 2
        if(window.scrollY > 0){
          this.completeColor = true;
          this.visibleFirstLine = false;
        }else{
          this.completeColor = false;
          this.visibleFirstLine = true;
        }
      }

      if(
        this.globalService.header.base_visible_message && 
        this.globalService.header.is_fixed
      ){

        if(Math.round(window.innerHeight * 0.10) <= window.scrollY){
          this.globalService.header.visible_message = false;
        }else{
          this.globalService.header.visible_message = true;
        }

      }

    }

  }


}
