<nav class="navbar">
  <ul class="categories">
    <li *ngFor="let link of menu" class="categories_text" [ngClass]="{'active-link': '' == url}">

      <app-element [text]="link.title" [url]="link.url" [target]="link.url | target"
        [showIconMore]="link.children?.length > 0">
      </app-element>

      <div class="categories_container" *ngIf="link.children?.length">
        <div class="categories_submenu container">
          <div>
            <ul class="categories_submenu_list" *ngFor="let child of link.children">
              <li class="categories_text_title">
                <a [class.event]="child.slug ? 'productos/'+child.slug : (child.url ? child.url : null)"
                  [attr.href]="child.slug ? 'productos/'+child.slug : (child.url ? child.url : null)">
                  {{child.title}}
                </a>
                <ul class="categories_submenu_list_inner" *ngIf="child.children?.length">
                  <ng-container *ngFor="let inner_children of child.children; let i = index;">
                    <li class="categories_text_subtitle" *ngIf="!limitSubElements || i < limitSubElements">
                      <a [class.event]="inner_children.slug ? 'productos/'+inner_children.slug : (inner_children.url ? inner_children.url : null)"
                        [attr.href]="inner_children.slug ? 'productos/'+inner_children.slug : (inner_children.url ? inner_children.url : null)">
                        {{inner_children.title}}
                      </a>
                    </li>
                  </ng-container>
                  <li class="categories_text_subtitle small" *ngIf="limitSubElements && child.children?.length >= 4">
                    <a class="event"
                      [attr.href]="child.slug ? 'productos/'+child.slug : (child.url ? child.url : null)">
                      Ver más
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </li>
  </ul>
</nav>
