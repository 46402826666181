import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, share } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  private requestCache: { [key: string]: Observable<any> } = {};

  constructor(private http: HttpClient) {
  }

  getIcon(icon: string): Observable<any> {

    if (!this.requestCache[icon]) {
      this.requestCache[icon] = this.http.get(icon, { responseType: 'text' } ).pipe(share());
      return this.requestCache[icon];
    }

    return this.requestCache[icon];

  }

}
