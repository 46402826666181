<div style="height: 100%;position: relative;">
  <div
    class="element desktop"
    (click)="globalService.isLogged ? (hover = !hover) : loginInit()"
    [ngClass]="{ hover: hover }"
  >
    <app-icon 
      class="icon" 
      icon="account_circle">
    </app-icon>
  </div>

  <div
    class="element mobile"
    (click)="globalService.isLogged ? openAccount() : loginInit()"
  >
    <app-icon 
      class="icon" 
      icon="account_circle">
    </app-icon>
  </div>

  <div class="element__content animated fadeIn faster" *ngIf="hover">
    <a [routerLink]="['/perfil']" >
      <app-icon
        class="icon"
        icon="account_circle"
      >
      </app-icon>
      <span> Mis datos </span>
      <app-icon icon="chevron_right"> </app-icon>
    </a>
    <a
      [routerLink]="['/perfil', 'compras']"
      routerLinkActive="active"

    >
      <app-icon
        class="icon"
        icon="shopping_cart"
      >
      </app-icon>
      <span> Mis compras </span>
      <app-icon icon="chevron_right"> </app-icon>
    </a>
    
    <a class="btn btn--primary" (click)="logout(); hover = false">
      <span> Cerrar sesión </span>
    </a>
  </div>

  <div
    class="backdrop animated fadeIn"
    *ngIf="hover"
    (mouseover)="hover = false"
  ></div>
</div>
