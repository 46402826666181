import { NgModule } from '@angular/core'
import { Routes, RouterModule, PreloadAllModules} from '@angular/router'
import { AuthGuardService } from './core/services/auth-guard.service'

// services

const routes: Routes = [

  {
    path: '',
    data: { overflow: 'hidden' },
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'compra',
    loadChildren: () => import('./features/purchase/purchase.module').then(m => m.PurchaseModule)
  },
  //Producto
  {
    path: 'producto',
    loadChildren: () => import('./features/products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'productos',
    loadChildren: () => import('./features/categories/categories.module').then(m => m.CategoriesModule)
  },
  {
    path: '',
    loadChildren: () => import('./features/abouts/abouts.module').then(m => m.AboutsModule)
  },
  {
    path: 'perfil',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./features/contact/contact.module').then(m => m.ContactModule)
  },

  { path: 'formulario-mayorista', loadChildren: () => import('./features/wholesale/wholesale.module').then(m => m.WholesaleModule) },
  {
    path: '**',
    loadChildren: () => import('./features/pages/pages.module').then(m => m.PagesModule)
  }

]


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Tell the router to use the hash instead of HTML5 pushstate.
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking',
    }
  )],
  exports: [RouterModule]
})

export class AppRoutingModule { }
