import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';

@Pipe({
  name: 'target',
  standalone: true
})
export class TargetPipe implements PipeTransform {

  constructor(private readonly _gb: GlobalService) {}

  transform(url: string): '_self' | '_blank' {
    if (url && this._gb.isBrowser) {
      try {
        let urlElement = new URL(url);
        if (
          urlElement &&
          urlElement.host &&
          urlElement.host === window.location.host
        ) {
          return '_self';
        } else {
          return '_blank';
        }
      } catch (err) {
        return '_self';
      }
    } else {
      return '_self';
    }
  }
  
}
